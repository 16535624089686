/**
 * @author: Ashay Tiwari
 * @date: 9 July 2021
 * @description: File for passing reducer function of student signup page 1
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  CONFIRM_PASSWORD,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PASSWORD,
  PHONE_CODE,
  PHONE_NUMBER,
  TERMS_AND_CONDITIONS
} from "../../constants/action-types/StudentSignup/signup1.actionTypes";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneCode: "14",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  termsAndConditions: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FIRST_NAME:
      return {
        ...state,
        firstName: payload
      };
    case LAST_NAME:
      return {
        ...state,
        lastName: payload
      };
    case EMAIL:
      return {
        ...state,
        email: payload
      };
    case PHONE_CODE:
      return {
        ...state,
        phoneCode: payload
      };
    case PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: payload
      };
    case PASSWORD:
      return {
        ...state,
        password: payload
      };
    case CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: payload
      };
    case TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditions: payload
      };
    default:
      return state;
  }
};
