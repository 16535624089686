/**
 * @author: Ashay Tiwari
 * @date: 8 July 2021
 * @description: config file for defining the urls
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
import axios from "axios";

const baseurl = process.env.REACT_APP_API_URL;
// console.log("api url: ", process.env.REACT_APP_API_URL);
export default axios.create({
  baseURL: baseurl
});
