/**
 * @author: Ashay Tiwari
 * @date: 5 Aug 2021
 * @description: File for company dashboard Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const COMPANY_STUDENT_LIST = "COMPANY_STUDENT_LIST";
export const COMPANY_FOLLOWING_LIST = "COMPANY_FOLLOWING_LIST";
export const COMPANY_LIST_OF_APPLICANTS = "COMPANY_LIST_OF_APPLICANTS";
export const GET_SUBSCRIPTION_LIST = "GET_SUBSCRIPTION_LIST";
export const COMPANY_BANNER = "COMPANY_BANNER";
