/**
 * @author: Ashay Tiwari
 * @date: 10 July 2021
 * @description: File for Company Signup Page 3 Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const COMPANY_EMPLOYEES = "COMPANY_EMPLOYEES";
export const COMPANY_INDUSTRY_FIELD = "COMPANY_INDUSTRY_FIELD";
export const COMPANY_SPECIALIZATION = "COMPANY_SPECIALIZATION";
export const COMPANY_BRANCHES = "COMPANY_BRANCHES";
export const COMPANY_LOCATION = "COMPANY_LOCATION";
