/**
 * @author: Ashay Tiwari
 * @date: 9 July 2021
 * @description: File for passing reducer function of student signup page 2
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  CITY,
  COUNTRY,
  DATE_OF_BIRTH,
  GENDER,
  HOME_ADDRESS,
  IMAGE_FILE,
  IMAGE_URL,
  POST_CODE,
  REGIONAL
} from "../../constants/action-types/StudentSignup/signup2.actionTypes";

const initialState = {
  imageFile: "",
  imageUrl: "",
  gender: "",
  dob: new Date(2000, 0, 1),
  homeAddress: "",
  city: "",
  regional: "",
  country: "14",
  postCode: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case IMAGE_FILE:
      return {
        ...state,
        imageFile: payload
      };
    case IMAGE_URL:
      return {
        ...state,
        imageUrl: payload
      };
    case GENDER:
      return {
        ...state,
        gender: payload
      };
    case DATE_OF_BIRTH:
      return {
        ...state,
        dob: payload
      };
    case HOME_ADDRESS:
      return {
        ...state,
        homeAddress: payload
      };
    case CITY:
      return {
        ...state,
        city: payload
      };
    case REGIONAL:
      return {
        ...state,
        regional: payload
      };
    case COUNTRY:
      return {
        ...state,
        country: payload
      };
    case POST_CODE:
      return {
        ...state,
        postCode: payload
      };
    default:
      return state;
  }
};
