/**
 * @author: Ashay Tiwari
 * @date: 2 Aug 2021
 * @description: File for student dashboard Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const STUDENT_COMPANY_LIST = "STUDENT_COMPANY_LIST";
export const STUDENT_COMPANY_FOLLOWING_LIST = "STUDENT_COMPANY_FOLLOWING_LIST";
export const STUDENT_RECOMMENDED_JOBS = "STUDENT_RECOMMENDED_JOBS";
export const STUDENT_APPLIED_JOBS_LIST = "STUDENT_APPLIED_JOBS_LIST";
export const STUDENT_BANNER = "STUDENT_BANNER";
export const BENEFIT_BANNER = "BENEFIT_BANNER";
export const EVENT_BANNER = "EVENT_BANNER";
export const BENEFIT_OFFER = "BENEFIT_OFFER";
export const EVENT_OFFER = "EVENT_OFFER";
