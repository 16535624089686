/**
 * @author: Ashay Tiwari
 * @date: 4 Aug 2021
 * @description: File for passing reducer function of student profile actions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
import {
  GET_BENEFITS,
  GET_EVENTS,
  GET_PARTNER_PROFILE,
  GET_PARTNER_SPECIFIC_EVENTS
} from "../constants/action-types/partnerWorld.actionTypes";

const initialState = {
  getBenefit: [],
  getEvents: [],
  getPartnerProfile: [],
  getPartnerSpecificEvents: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BENEFITS:
      return {
        ...state,
        getBenefit: payload
      };
    case GET_EVENTS:
      return {
        ...state,
        getEvents: payload
      };
    case GET_PARTNER_PROFILE:
      return {
        ...state,
        getPartnerProfile: payload
      };
    case GET_PARTNER_SPECIFIC_EVENTS:
      return {
        ...state,
        getPartnerSpecificEvents: payload
      };
    default:
      return state;
  }
};
