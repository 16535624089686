/**
 * @author: Ashay Tiwari
 * @date: 10 July 2021
 * @description: File for Company Signup Page 1 Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const COMPANY_FIRST_NAME = "COMPANY_FIRST_NAME";
export const COMPANY_LAST_NAME = "COMPANY_LAST_NAME";
export const COMPANY_GENDER = "COMPANY_GENDER";
export const COMPANY_NAME = "COMPANY_NAME";
export const COMPANY_POSITION = "COMPANY_POSITION";
export const COMPANY_EMAIL_ADDRESS = "COMPANY_EMAIL_ADDRESS";
export const COMPANY_PHONE_CODE = "COMPANY_PHONE_CODE";
export const COMPANY_PHONE_NUMBER = "COMPANY_PHONE_NUMBER";
export const COMPANY_PASSWORD = "COMPANY_PASSWORD";
export const COMPANY_CONFIRM_PASSWORD = "COMPANY_CONFIRM_PASSWORD";
export const COMPANY_STATE_ENUM = " COMPANY_STATE_ENUM";
export const COMPANY_TERMS_AND_CONDITIONS = "COMPANY_TERMS_AND_CONDITIONS";
