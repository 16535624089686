/**
 * @author: Ashay Tiwari
 * @date: 10 July 2021
 * @description: File for Company Signup Page 2 Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const COMPANY_IMAGE_FILE = "COMPANY_IMAGE_FILE";
export const COMPANY_IMAGE_URL = "COMPANY_IMAGE_URL";
export const COMPANY_DESCRIPTION = "COMPANY_DESCRIPTION";
export const COMPANY_PHILOSOPHY = "COMPANY_PHILOSOPHY";
export const COMPANY_ACTIVITY = "COMPANY_ACTIVITY";
export const COMPANY_CULTURE = "COMPANY_CULTURE";
export const COMPANY_CITY = "COMPANY_CITY";
export const COMPANY_REGIONAL = "COMPANY_REGIONAL";
export const COMPANY_COUNTRY = "COMPANY_COUNTRY";
export const COMPANY_POST_CODE = "COMPANY_POST_CODE";
