/**
 * @author: Ashay Tiwari
 * @date: 4 Aug 2021
 * @description: File for Partner World Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const GET_BENEFITS = "GET_BENEFITS";
export const GET_EVENTS = "GET_EVENTS";
export const GET_PARTNER_PROFILE = "GET_PARTNER_PROFILE";
export const GET_PARTNER_SPECIFIC_EVENTS = "GET_PARTNER_SPECIFIC_EVENTS";
