/**
 * @author: Ashay Tiwari
 * @date: 9 July 2021
 * @description: File for Landing Page
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import React, { Suspense, useEffect, useState } from "react";
import { getSessionStorage } from "../../../middlewares/globalFunction";
import { Redirect, useHistory } from "react-router-dom";
import "../../../i18n/i18n";
import Logo from "../../../component/HipozLandingPages/LandingPage/Logo/Logo";
import { TERMS_CONTENT } from "../../../redux/constants/action-types/common.actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getApi } from "../../../middlewares/apiHandler";
import { setTermsData } from "../../../redux/actions/common.actions";
import Loader from "../../../component/UI/Loader/Loader";
import globalStyles from "../../../App.css";
import { Navbar } from "react-bootstrap";
import styles from "./Agreement.css";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { Document, Page } from 'react-pdf';
// import agreement from '../../../assets/datenschutzrichtlinie_hipoz.pdf'


const Agreement = (props) => {
  const [getTermsQuery, setGetTermsQuery] = useState("");
  const [isApiFetch, setIsApiFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const getTerms = useQuery(
    ["getTerms", getTermsQuery],
    () => getApi(getTermsQuery),
    { enabled: isApiFetch }
  );

  const [fixed, setFixed] = useState(false)

  const terms = useSelector(
    (state) => state.common.terms
  );

  useEffect(()=>{
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  })

  useEffect(() => {
    setGetTermsQuery(`getTerms`);
    setIsApiFetch(true);
  }, [setGetTermsQuery]);

  useEffect(() => {
    if (getTerms.data && getTerms.data.statuscode === 200) {
      dispatch(
        setTermsData(TERMS_CONTENT, getTerms.data.data)
      );
    }
    return () => {
      dispatch(setTermsData(TERMS_CONTENT, ""));
    };
  }, [dispatch, getTerms]);

  const handleScroll = () => {
    if (window.pageYOffset > 140) {
      setFixed(true)
    }else{
      setFixed(false)
    }
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  function createMarkup() { return {__html: terms && terms[0].terms}; };

  return (
    <div className={styles.bgimage}>
      {/* <Helmet title={LoginTitle} /> */}
      {isLoading ? (
        <div className={globalStyles.loaderWrapper}>
          <Loader />
        </div>
      ) : (
        <React.Fragment>
          <div className={"container p-0"}>
            <div className={`row ${styles.contant}`}>
              <div className={"col-md-12 p-0"}>
                <div className={styles.mainForm}>
                  <div className={styles.iconandhead}>
                    <h3 className={styles.signtext}>Datenschutzrichtlinie</h3>
                    <CloseIcon
                      className={styles.closeIcon}
                      onClick={() => history.push("/")}
                    />
                  </div>
                  <embed style={{ height: 600, width : '100%' }} src={'/datenschutzrichtlinie_hipoz.pdf#toolbar=0&navpanes=0&scrollbar=0'} type="application/pdf"/>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Agreement;
