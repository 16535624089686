/**
 * @author: Ashay Tiwari
 * @date: 9 July 2021
 * @description: File for Landing Page
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
 import React from "react";
 import { NavLink } from "react-router-dom";
 import Navbar from "../Navbar/Navbar.js";
 import style from "./Logo.css";
 import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
 import "../../../../i18n/i18n";
 import { useTranslation } from "react-i18next";
 import Background from '../../../../assets/landing_bg.png';
 import profil from '../../../../assets/images/powered_by_profil.png'

 
 const Logo = (props) => {
   const { t } = useTranslation();
 
   /**
    * function to handle scoll action
    */
   const handleScroll = () => {
     window.scrollTo(0, 800);
   }; // end of handleScroll
 
   return (
        <div className={style.banner}>
            <Navbar {...props} />
            <div className="d-lg-flex position-relative" 
                style={{backgroundImage: `url(${Background})`, backgroundSize: "cover"}}>
                <div style={{ minHeight: '100vh' }} className="container text-center d-lg-flex align-items-lg-center space-top-2 space-lg-0 min-vh-lg-100 py-5" >
                    <div style={{ width: '100%' }} className="w-md-100">
                        <div className="row">
                            <div className="col-md-12 text-center branding">
                                <svg className="animated fadeInUp mobile-svg" width="240" height="276" viewBox="0 0 240 276" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M88.668 74.8101C88.668 74.6572 88.6571 74.5152 88.6571 74.3623V74.1438H88.6462C88.2748 50.8128 69.2583 32.0037 45.8398 32.0037C37.1999 32.0037 29.1607 34.5706 22.4323 38.9725V0H2.98975L3.89636 146.038C9.06283 138.829 15.2888 132.428 22.3449 127.054L22.4323 73.5649C23.0877 61.2003 33.3114 51.3808 45.8398 51.3808C58.5649 51.3808 68.9196 61.528 69.2692 74.1657V109.184C71.5302 109.009 73.824 108.922 76.1287 108.922C80.3885 108.922 84.5829 109.228 88.679 109.807L88.6462 74.7883H88.668V74.8101Z" fill="white"/>
                                    <path d="M102.708 32.6836L102.719 112.769C109.044 114.735 115.062 117.401 120.687 120.645L120.611 32.6836H102.708Z" fill="white"/>
                                    <path d="M187.724 31.8931C159.555 31.8931 136.595 54.1755 135.492 82.0723H135.404V131.247C142.46 137.505 148.523 144.867 153.307 153.07V123.513C162.504 131.563 174.541 136.457 187.724 136.457C216.604 136.457 240.001 113.049 240.001 84.1804C240.012 55.3006 216.604 31.8931 187.724 31.8931ZM187.724 118.423C168.817 118.423 153.481 103.088 153.481 84.1804C153.481 65.2621 168.817 49.9375 187.724 49.9375C206.632 49.9375 221.967 65.2731 221.967 84.1804C221.967 103.088 206.643 118.423 187.724 118.423Z" fill="white"/>
                                    <path d="M111.493 0.621582C105.071 0.621582 100.451 5.53684 100.472 11.9594C100.44 18.3383 104.852 23.2754 111.166 23.2754C118.233 23.2754 122.525 18.3383 122.493 11.9594C122.471 5.44946 118.167 0.621582 111.493 0.621582Z" fill="white"/>
                                    <path d="M77.519 120.257C34.7017 120.257 0 154.97 0 197.776C0 225.706 14.7785 250.184 36.9408 263.826L45.9412 249.354C29.0874 238.66 17.8806 219.84 17.8806 198.399C17.8806 165.084 44.8926 138.072 78.2071 138.072C111.522 138.072 138.534 165.084 138.534 198.399C138.534 231.713 111.522 258.725 78.2071 258.725C77.7811 258.725 77.366 258.704 76.951 258.693L115.301 186.286L52.2109 186.635L41.5612 204.942L86.6286 204.778L57.5849 255.088C57.5412 255.077 57.4975 255.055 57.4538 255.044L48.7702 269.746C57.6613 273.296 67.3499 275.273 77.519 275.273C120.336 275.273 155.038 240.572 155.038 197.754C155.038 154.937 120.336 120.257 77.519 120.257Z" fill="#36A8B7"/>
                                </svg>
                                <p>
                                    <a target="_blank" href="https://www.profil.at/">
                                        <img src={profil} style={{ height: 100, marginTop: 5 }} />
                                    </a>
                                </p>
                                <p style={{ color: '#fff', 
                                    lineHeight: '200%',
                                    letterSpacing: '-0.01em',
                                    fontWeight: 'normal',
                                    fontStyle: 'normal',
                                    opacity: 0.6, fontFamily: 'Avenir', fontSize: 24, }} className="uppercase d-block-text hipoz-subheading">The High Potential Zone </p>
                            </div>
                        </div>
                        <div className={style.circle} style={{ marginBottom: 10 }}>
                            <NavLink to="/student/signup">
                                <button className={style.buttonfilleffecthipoz}>
                                <span>{t("common.signup")}</span>
                                </button>
                            </NavLink>
                            </div>
                        <div className={style.scroll} onClick={() => handleScroll()}>
                            <p className={style.srocll} onClick={() => handleScroll()}>
                                {t("homePage.banner.scrollMore")}
                            </p>
                            <KeyboardArrowDownIcon className={style.icon} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
 
 export default Logo;
 