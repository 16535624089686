/* eslint-disable array-callback-return */
/**
 * @author: Ashay Tiwari
 * @date: 12 Apr 2021
 * @description: File for exporting global functions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
import { useState, useCallback, useRef, useEffect } from "react";
import CryptoJs from "crypto-js";
import { SessionEncryptionSecretKey } from "./configFileAccessor";

/**
 * Function to reset the upcoming fields
 * @param  {...any} setState is coming as props
 */
export const resetForm = (...props) => {
  props.forEach((setState) => setState(""));
}; //end of resetForm

/**
 * Cutom Hook for synchronous useState
 * @param {*} initialValue
 * @returns
 */
export function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // mutable ref to store current callback

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store passed callback to ref
    setState(state);
  }, []);

  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
} // end of useStateCallback

/**
 * function to encrypt the user data and store it in session storage
 * @param {*} userData
 */
export const setSessionStorage = (userData) => {
  let cipherText = CryptoJs.AES.encrypt(
    JSON.stringify(userData),
    SessionEncryptionSecretKey
  ).toString();
  localStorage.setItem("user_info", cipherText);
}; // end of setSessionStorage

/**
 * function to get user details from session storage and decrypt it
 * @returns user from session storage
 */
export const getSessionStorage = () => {
  let userFromSession = localStorage.getItem("user_info");
  if (userFromSession) {
    let bytes = CryptoJs.AES.decrypt(
      userFromSession,
      SessionEncryptionSecretKey
    );
    let decryptedData = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
    return decryptedData;
  } else {
    return userFromSession;
  }
}; // end of getSessionStorage

export const AdminType = "Admin";
export const UserType = "User";

/**
 * function to remove the user info form session storage
 */
export const clearSessionStorage = () => {
  localStorage.removeItem("user_info");
}; // end of clearSessionStorage

export const extractEnumIdFromDataArray = (data) => {
  let array = [];
  if (data && data.length !== 0) {
    data.map((item) => {
      array.push(parseInt(item.enum_id));
    });
  }
  return array;
};
