/**
 * @author: Ashay Tiwari
 * @date: 5 Aug 2021
 * @description: File for Common Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const NEWS_FEED = "NEWS_FEED";
export const SEARCH_ENGINE_RESULT = "SEARCH_ENGINE_RESULT";
export const STUDENT_SIGNUP_CONTENT = "STUDENT_SIGNUP_CONTENT";
export const COMPANY_SIGNUP_CONTENT = "COMPANY_SIGNUP_CONTENT";
export const TERMS_CONTENT = "TERMS_CONTENT";
