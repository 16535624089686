/**
 * @author: Ashay Tiwari
 * @date: 6 Sep 2021
 * @description: Configuration file for react-i18next for language localization
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATION_GERMAN } from "./de/translations";
import { TRANSLATION_ENGLISH } from "./en/translations";

// Multiple language json handling
const resources = {
  en: { translation: TRANSLATION_ENGLISH },
  de: { translation: TRANSLATION_GERMAN }
};

const language = localStorage.getItem("language");

// default language of project will set here with ISO code, e.g. en for English
const defaultLanguage = language ? "de" : language;

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: defaultLanguage
});

i18n.changeLanguage(defaultLanguage);

export default i18n;
