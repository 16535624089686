/**
 * @author: Ashay Tiwari
 * @date: 10 July 2021
 * @description: File for passing reducer function of student signup page 3
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
import {
  STUDENT_GRADUATED_HISTORY,
  STUDENT_SCHOLARSHIP,
  STUDENT_SCHOLARSHIP_NAME,
  STUDENT_SCHOLARSHIP_TYPE,
  STUDENT_WORK_AVAILABILTY
} from "../../constants/action-types/StudentSignup/signup3.actionTypes";

const initialState = {
  studentScholarship: "134",
  studentScholarshipType: "",
  studentScholarshipName: "",
  studentWorkAvailabilty: "17",
  studentGraduatedHistory: [
    {
      degree: "",
      studyField: "",
      university: "",
      graduatedMonth: "",
      graduatedYear: "",
      graduatedStartMonth: "",
      graduatedStartYear: "",
      studying: 0
    }
  ]
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STUDENT_SCHOLARSHIP:
      return {
        ...state,
        studentScholarship: payload
      };
    case STUDENT_SCHOLARSHIP_NAME:
      return {
        ...state,
        studentScholarshipName: payload
      };
    case STUDENT_SCHOLARSHIP_TYPE:
      return {
        ...state,
        studentScholarshipType: payload
      };
    case STUDENT_WORK_AVAILABILTY:
      return {
        ...state,
        studentWorkAvailabilty: payload
      };
    case STUDENT_GRADUATED_HISTORY:
      return {
        ...state,
        studentGraduatedHistory: payload
      };
    default:
      return state;
  }
};
