/**
 * @author: Ashay Tiwari
 * @date: 24 Apr 2021
 * @description: File for Enum Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

export const GET_COMPANY_WORKING_LOCATION = "GET_COMPANY_WORKING_LOCATION";
export const GET_COMPANY_SIZE_ENUM = "GET_COMPANY_SIZE_ENUM";
export const GET_ROLE_AVAILABLE_TYPE_ENUM = "GET_ROLE_AVAILABLE_TYPE_ENUM";
export const GET_ROLE_EMPLOYMENT_TYPE_ENUM = "GET_ROLE_EMPLOYMENT_TYPE_ENUM";
export const GET_ROLE_JOB_INTEREST_ENUM = "GET_ROLE_JOB_INTEREST_ENUM";
export const GET_TECH_EXP_SKILLS_ENUM = "GET_TECH_EXP_SKILLS_ENUM";
export const GET_ROLE_SALARY_UNIT_ENUM = "GET_ROLE_SALARY_UNIT_ENUM";
export const GET_ROLE_CURRENCY_TYPE_ENUM = "GET_ROLE_CURRENCY_TYPE_ENUM";
export const GET_TECH_EXPERTISE_LEVEL_ENUM = "GET_TECH_EXPERTISE_LEVEL_ENUM";
export const GET_GENDER_ENUM = "GET_GENDER_ENUM";
export const GET_COUNTRY_ENUM = "GET_COUNTRY_ENUM";
export const GET_STATE_ENUM = "GET_STATE_ENUM";
export const GET_CITY_ENUM = "GET_CITY_ENUM";
export const GET_DEGREE = "GET_DEGREE";
export const GET_FIELD_OF_STUDY = "GET_FIELD_OF_STUDY";
export const GET_UNIVERSITY = "GET_UNIVERSITY";
export const GET_MONTH = "GET_MONTH";
export const GET_YEAR = "GET_YEAR";
export const GET_ROLE_EXPECTATION = "GET_ROLE_EXPECTATION";
export const GET_INTERESTED_WORK = "GET_INTERESTED_WORK";
export const GET_INDUSTRY_FIELD = "GET_INDUSTRY_FIELD";
export const GET_SCHOLARSHIP_TYPE = "GET_SCHOLARSHIP_TYPE";
export const GET_CONDITION_ENUM = "GET_CONDITION_ENUM";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_SPECIALIZATION = "GET_SPECIALIZATION";
export const GET_EMPLOYEES_RANGE = "GET_EMPLOYEES_RANGE";
export const GET_BRANCHES = "GET_BRANCHES";
export const GET_HIRING_STATUS = "GET_HIRING_STATUS";
export const GET_YEARS_OF_EXP_ENUM = "GET_YEARS_OF_EXP_ENUM";
