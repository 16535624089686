/**
 * @author: Ashay Tiwari
 * @date: 10 July 2021
 * @description: File for Student Signup Page 2 Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const IMAGE_FILE = "IMAGE_FILE";
export const IMAGE_URL = "IAMGE_URL";
export const GENDER = "GENDER";
export const DATE_OF_BIRTH = "DATE_OF_BIRTH";
export const HOME_ADDRESS = "HOME_ADDRESS";
export const CITY = "CITY";
export const REGIONAL = "REGIONAL";
export const COUNTRY = "COUNTRY";
export const POST_CODE = "POST_CODE";
