import React from "react";
import Loader from "react-loader-spinner";
import styles from "./Loader.css";

function LoaderComponent() {
  return (
    <div className={styles.loaderWrapper}>
      <Loader type={"Bars"} color="#456fec" height={50} width={50} />
      <p className={styles.loaderText}>Loading...</p>
    </div>
  );
}

export default LoaderComponent;
