/**
 * @author: Ashay Tiwari
 * @date: 9 July 2021
 * @description: File for Student Signup Page 1 Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const FIRST_NAME = "FIRST_NAME";
export const LAST_NAME = "LAST_NAME";
export const EMAIL = "EMAIL";
export const PHONE_NUMBER = "PHONE_NUMBER";
export const PHONE_CODE = "PHONE_CODE";
export const PASSWORD = "PASSWORD";
export const CONFIRM_PASSWORD = "CONFIRM_PASSWORD";
export const TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS";
