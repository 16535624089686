/**
 * @author: Ashay Tiwari
 * @date: 6 Sep 2021
 * @description: Component for language toggling
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
import React, { useState, useEffect } from "react";
import { Switch, createMuiTheme, ThemeProvider } from "@material-ui/core";
import styles from "./LanguageToggler.css";
import i18n from "../../../i18n/i18n";
import "../../../i18n/i18n";
import { useTranslation } from "react-i18next";

/**
 * Color Theme for switches
 */
const switchColorTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#36a8b7"
    }
  }
});

const LanguageToggler = (props) => {
  const [isGerman, setIsGerman] = useState(false);
  const { t } = useTranslation();
  const language = localStorage.getItem("language");
  const i18nLanguage = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (language === "en") {
      setIsGerman(false);
    } else if (language === "de") {
      setIsGerman(true);
    } else {
      setIsGerman(i18nLanguage === "de" ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const languageChangeHandler = (value) => {
    setIsGerman(value);
    i18n.changeLanguage(value ? "de" : "en");
    localStorage.setItem("language", value ? "de" : "en");
  };

  return (
    <div className={"d-flex"}>
      <p className={props.theme === "white" ? styles.blackLabel : styles.label}>
        {t("common.english")}
      </p>
      <ThemeProvider theme={switchColorTheme}>
        <Switch
          checked={isGerman}
          onChange={(e) => languageChangeHandler(e.target.checked)}
          name={"status-toggler"}
          className={styles.switch}
        />
      </ThemeProvider>
      <p className={props.theme === "white" ? styles.blackLabel : styles.label}>
        {t("common.german")}
      </p>
    </div>
  );
};

export default LanguageToggler;
