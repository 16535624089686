/**
 * @author: Ashay Tiwari
 * @date: 5 Aug 2021
 * @description: File for common actions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

/**
 * function to store the common  data in redux store
 * @param {*} data
 */
export const setCommonData = (type, data) => async (dispatch) => {
  dispatch({
    type: type,
    payload: data
  });
}; // end of setCommonData

export const setTermsData = (type, data) => async (dispatch) => {
  dispatch({
    type: type,
    payload: data
  });
}; // end of setCompanyProfileData
