/**
 * @author: Ashay Tiwari
 * @date: 10 July 2021
 * @description: File for passing reducer function of company signup page 2
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  COMPANY_ACTIVITY,
  COMPANY_CITY,
  COMPANY_COUNTRY,
  COMPANY_CULTURE,
  COMPANY_DESCRIPTION,
  COMPANY_IMAGE_FILE,
  COMPANY_IMAGE_URL,
  COMPANY_PHILOSOPHY,
  COMPANY_POST_CODE,
  COMPANY_REGIONAL
} from "../../constants/action-types/CompanySignup/companySignup2.actionTypes";

const initialState = {
  companyImageFile: "",
  companyImageUrl: "",
  companyDescription: "",
  companyPhilosophy: "",
  companyActivity: "",
  companyCulture: "",
  companyCity: "",
  companyRegional: "",
  companyCountry: "14",
  companyPostCode: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPANY_IMAGE_FILE:
      return {
        ...state,
        companyImageFile: payload
      };
    case COMPANY_IMAGE_URL:
      return {
        ...state,
        companyImageUrl: payload
      };
    case COMPANY_DESCRIPTION:
      return {
        ...state,
        companyDescription: payload
      };
    case COMPANY_PHILOSOPHY:
      return {
        ...state,
        companyPhilosophy: payload
      };
    case COMPANY_ACTIVITY:
      return {
        ...state,
        companyActivity: payload
      };
    case COMPANY_CULTURE:
      return {
        ...state,
        companyCulture: payload
      };
    case COMPANY_CITY:
      return {
        ...state,
        companyCity: payload
      };
    case COMPANY_REGIONAL:
      return {
        ...state,
        companyRegional: payload
      };
    case COMPANY_COUNTRY:
      return {
        ...state,
        companyCountry: payload
      };
    case COMPANY_POST_CODE:
      return {
        ...state,
        companyPostCode: payload
      };

    default:
      return state;
  }
};
