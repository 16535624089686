/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../../../../assets/images/logoo.png";
import { NavLink } from "react-router-dom";
import style from "./Navbar.css";
import {
  clearSessionStorage,
  getSessionStorage
} from "../../../../middlewares/globalFunction";
import { useHistory } from "react-router";
import LanguageToggler from "../../../UI/LanguageToggler/LanguageToggler";
import "../../../../i18n/i18n";
import { useTranslation } from "react-i18next";

const Navbar = (props) => {
  const user = getSessionStorage();
  const history = useHistory();
  const { t } = useTranslation();

  /**
   * function to handle logout
   */
  const logoutHandler = () => {
    clearSessionStorage();
    history.push("/");
  }; // end of logoutHandler

  return (
    <nav style={props.fixed ? { position: "fixed", top: 0, left: 0, right: 0, zIndex: 9999, backgroundColor: "#1E1D2B" }: {}} className={style.navbar}>
      <div className={style.navbar__text}>
        <div className={style.logo}>
          <img src={logo} className={style.logoimg} alt="logo" />
        </div>
        <ul className={style.nav_ul}>
          {/* <li>
            <LanguageToggler />
          </li> */}
          {user ? (
            <li>
              {/* <a onClick={() => logoutHandler()}>Sign out</a> */}
              <button
                className={style.buttonfilleffecthipoz}
                onClick={() => logoutHandler()}
              >
                <span>{t("common.logOut")}</span>
              </button>
            </li>
          ) : (
            <li>
              <NavLink to="/signin">
                <button className={style.buttonfilleffecthipoz}>
                  <span>{t("common.signin")}</span>
                </button>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
