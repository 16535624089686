/**
 * @author: Ashay Tiwari
 * @date: 24 Apr 2021
 * @description: File for passing reducer function of enums
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  GET_COMPANY_WORKING_LOCATION,
  GET_COMPANY_SIZE_ENUM,
  GET_ROLE_AVAILABLE_TYPE_ENUM,
  GET_ROLE_EMPLOYMENT_TYPE_ENUM,
  GET_ROLE_JOB_INTEREST_ENUM,
  GET_TECH_EXP_SKILLS_ENUM,
  GET_ROLE_SALARY_UNIT_ENUM,
  GET_ROLE_CURRENCY_TYPE_ENUM,
  GET_TECH_EXPERTISE_LEVEL_ENUM,
  GET_GENDER_ENUM,
  GET_COUNTRY_ENUM,
  GET_STATE_ENUM,
  GET_CITY_ENUM,
  GET_DEGREE,
  GET_UNIVERSITY,
  GET_FIELD_OF_STUDY,
  GET_MONTH,
  GET_YEAR,
  GET_ROLE_EXPECTATION,
  GET_INTERESTED_WORK,
  GET_INDUSTRY_FIELD,
  GET_SCHOLARSHIP_TYPE,
  GET_CONDITION_ENUM,
  GET_LANGUAGES,
  GET_SPECIALIZATION,
  GET_EMPLOYEES_RANGE,
  GET_BRANCHES,
  GET_HIRING_STATUS,
  GET_YEARS_OF_EXP_ENUM
} from "../constants/action-types/enum.actionTypes";
import { COMPANY_STATE_ENUM } from "../constants/action-types/CompanySignup/companySignup1.actionTypes";
const initialState = {
  companyWorkingLocationEnum: [],
  companySizeEnum: [],
  roleAvailableTypeEnum: [],
  roleEmploymentTypeEnum: [],
  roleJobInterestEnum: [],
  techExpSkillsEnum: [],
  roleSalaryUnitEnum: [],
  roleCurrencyTypeEnum: [],
  techExpertiseLevelEnum: [],
  genderEnum: [],
  countryEnum: [],
  stateEnum: [],
  cityEnum: [],
  degreeEnum: [],
  universityEnum: [],
  fieldOfStudyEnum: [],
  monthEnum: [],
  yearEnum: [],
  roleExpectationEnum: [],
  interestedWorkEnum: [],
  industryFieldEnum: [],
  scholarshipTypeEnum: [],
  conditionEnum: [],
  languagesEnum: [],
  specializationEnum: [],
  employeeRangeEnum: [],
  branchEnum: [],
  hiringStatusEnum: [],
  yrsOfExpEnum: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_COMPANY_WORKING_LOCATION:
      return {
        ...state,
        companyWorkingLocationEnum: payload
      };
    case GET_COMPANY_SIZE_ENUM:
      return {
        ...state,
        companySizeEnum: payload
      };
    case GET_ROLE_AVAILABLE_TYPE_ENUM:
      return {
        ...state,
        roleAvailableTypeEnum: payload
      };
    case GET_ROLE_EMPLOYMENT_TYPE_ENUM:
      return {
        ...state,
        roleEmploymentTypeEnum: payload
      };
    case GET_ROLE_JOB_INTEREST_ENUM:
      return {
        ...state,
        roleJobInterestEnum: payload
      };
    case GET_TECH_EXP_SKILLS_ENUM:
      return {
        ...state,
        techExpSkillsEnum: payload
      };
    case GET_ROLE_SALARY_UNIT_ENUM:
      return {
        ...state,
        roleSalaryUnitEnum: payload
      };
    case GET_ROLE_CURRENCY_TYPE_ENUM:
      return {
        ...state,
        roleCurrencyTypeEnum: payload
      };
    case GET_TECH_EXPERTISE_LEVEL_ENUM:
      return {
        ...state,
        techExpertiseLevelEnum: payload
      };
    case GET_GENDER_ENUM:
      return {
        ...state,
        genderEnum: payload
      };
    case GET_COUNTRY_ENUM:
      return {
        ...state,
        countryEnum: payload
      };
    case GET_STATE_ENUM:
      return {
        ...state,
        stateEnum: payload
      };
    case GET_CITY_ENUM:
      return {
        ...state,
        cityEnum: payload
      };
    case COMPANY_STATE_ENUM:
      return {
        ...state,
        stateEnum: payload
      };
    case GET_DEGREE:
      return {
        ...state,
        degreeEnum: payload
      };
    case GET_UNIVERSITY:
      return {
        ...state,
        universityEnum: payload
      };
    case GET_FIELD_OF_STUDY:
      return {
        ...state,
        fieldOfStudyEnum: payload
      };
    case GET_MONTH:
      return {
        ...state,
        monthEnum: payload
      };
    case GET_YEAR:
      return {
        ...state,
        yearEnum: payload
      };
    case GET_ROLE_EXPECTATION:
      return {
        ...state,
        roleExpectationEnum: payload
      };
    case GET_INTERESTED_WORK:
      return {
        ...state,
        interestedWorkEnum: payload
      };
    case GET_INDUSTRY_FIELD:
      return {
        ...state,
        industryFieldEnum: payload
      };
    case GET_SCHOLARSHIP_TYPE:
      return {
        ...state,
        scholarshipTypeEnum: payload
      };
    case GET_CONDITION_ENUM:
      return {
        ...state,
        conditionEnum: payload
      };
    case GET_LANGUAGES:
      return {
        ...state,
        languagesEnum: payload
      };
    case GET_SPECIALIZATION:
      return {
        ...state,
        specializationEnum: payload
      };
    case GET_EMPLOYEES_RANGE:
      return {
        ...state,
        employeeRangeEnum: payload
      };
    case GET_BRANCHES:
      return {
        ...state,
        branchEnum: payload
      };
    case GET_HIRING_STATUS:
      return {
        ...state,
        hiringStatusEnum: payload
      };
    case GET_YEARS_OF_EXP_ENUM:
      return {
        ...state,
        yrsOfExpEnum: payload
      };
    default:
      return state;
  }
};
