/**
 * @author: Ashay Tiwari
 * @date: 23 AUg 2021
 * @description: File for Company Signup Page 5 Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const SUBSCRIPTION_PLAN_ID = "SUBSCRIPTION_PLAN_ID";
export const SUBSCRIPTION_PRICE = "SUBSCRIPTION_PRICE";
export const SUBSCRIPTION_TYPE_ID = "SUBSCRIPTION_TYPE_ID";
export const SUBSCRIPTION_NO_OF_JOBS = "SUBSCRIPTION_NO_OF_JOBS";
