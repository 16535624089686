/**
 * @author: Ashay Tiwari
 * @date: 23 AUg 2021
 * @description: File for passing reducer function of company signup page 5
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  SUBSCRIPTION_NO_OF_JOBS,
  SUBSCRIPTION_PLAN_ID,
  SUBSCRIPTION_PRICE,
  SUBSCRIPTION_TYPE_ID
} from "../../constants/action-types/CompanySignup/companySignup5.actionTypes";

const initialState = {
  subscriptionPlanId: "",
  subscriptionPrice: "",
  subscriptionTypeId: "",
  subscriptionJobs: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBSCRIPTION_PLAN_ID:
      return {
        ...state,
        subscriptionPlanId: payload
      };
    case SUBSCRIPTION_PRICE:
      return {
        ...state,
        subscriptionPrice: payload
      };
    case SUBSCRIPTION_TYPE_ID:
      return {
        ...state,
        subscriptionTypeId: payload
      };
    case SUBSCRIPTION_NO_OF_JOBS:
      return {
        ...state,
        subscriptionJobs: payload
      };
    default:
      return state;
  }
};
