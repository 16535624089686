/**
 * @author: Ashay Tiwari
 * @date: 6 Apr 2021
 * @description: File for creating store and exporting them.
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const { createStore, applyMiddleware } = require("redux");

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
