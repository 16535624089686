/**
 * @author: Ashay Tiwari
 * @date: 16 July 2021
 * @description: File for passing reducer function of student profile actions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  STUDENT_PROFILE,
  ST_PROFILE_EDUCATION,
  ST_PROFILE_LANGUAGE,
  ST_PROFILE_SALARY,
  ST_PROFILE_SCHOLARSHIP,
  ST_PROFILE_SKILLS,
  ST_PROFILE_STUDY_ABROAD,
  ST_PROFILE_WORK
} from "../constants/action-types/studentProfile.actionTypes";

const initialState = {
  basicProfile: [],
  stProfileEducation: [],
  stProfileWork: [],
  stProfileStudyAbroad: [],
  stProfileScholarship: [],
  stProfileLanguage: [],
  stProfileSkills: [],
  stProfileSalary: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STUDENT_PROFILE:
      return {
        ...state,
        basicProfile: payload
      };
    case ST_PROFILE_EDUCATION:
      return {
        ...state,
        stProfileEducation: payload
      };
    case ST_PROFILE_WORK:
      return {
        ...state,
        stProfileWork: payload
      };
    case ST_PROFILE_STUDY_ABROAD:
      return {
        ...state,
        stProfileStudyAbroad: payload
      };
    case ST_PROFILE_SCHOLARSHIP:
      return {
        ...state,
        stProfileScholarship: payload
      };
    case ST_PROFILE_LANGUAGE:
      return {
        ...state,
        stProfileLanguage: payload
      };
    case ST_PROFILE_SKILLS:
      return {
        ...state,
        stProfileSkills: payload
      };
    case ST_PROFILE_SALARY:
      return {
        ...state,
        stProfileSalary: payload
      };
    default:
      return state;
  }
};
