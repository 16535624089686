/**
 * @author: Ashay Tiwari
 * @date: 5 Aug 2021
 * @description: File for passing reducer function of common actions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  COMPANY_SIGNUP_CONTENT,
  NEWS_FEED,
  SEARCH_ENGINE_RESULT,
  STUDENT_SIGNUP_CONTENT,
  TERMS_CONTENT
} from "../constants/action-types/common.actionTypes";

const initialState = {
  newsFeed: [],
  searchResult: [],
  studentSignupContent: [],
  companySignupContent: [],
  terms: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NEWS_FEED:
      return {
        ...state,
        newsFeed: payload
      };
    case SEARCH_ENGINE_RESULT:
      return {
        ...state,
        searchResult: payload
      };
    case STUDENT_SIGNUP_CONTENT:
      return {
        ...state,
        studentSignupContent: payload
      };
    case COMPANY_SIGNUP_CONTENT:
      return {
        ...state,
        companySignupContent: payload
      };
    case TERMS_CONTENT:
      return {
        ...state,
        terms: payload
      };
    default:
      return state;
  }
};
