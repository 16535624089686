import API from "../services/api";

/**
 * function to handle post apis
 * @param {*} arg argument object coming from mutate function
 * arg contains following properties:
 * endPoint:- endPoint of api
 * param:- parameters for post api
 * @returns response of api
 */
export const postApi = async (arg) => {
  const { data } = await API.post(arg.endPoint, arg.param);
  return data;
}; // end of postApi

/**
 * function to handle get apis
 * @param {*} arg contains endpoint concatenated with query
 * @returns response of api
 */
export const getApi = async (arg) => {
  const { data } = await API.get(arg);
  return data;
}; // end of getApi
