/**
 * @author: Ashay Tiwari
 * @date: 16 July 2021
 * @description: File for student profile Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const STUDENT_PROFILE = "STUDENT_PROFILE";
export const ST_PROFILE_EDUCATION = "ST_PROFILE_EDUCATION";
export const ST_PROFILE_WORK = "ST_PROFILE_WORK";
export const ST_PROFILE_STUDY_ABROAD = "ST_PROFILE_STUDY_ABROAD";
export const ST_PROFILE_SCHOLARSHIP = "ST_PROFILE_SCHOLARSHIP";
export const ST_PROFILE_LANGUAGE = "ST_PROFILE_LANGUAGE";
export const ST_PROFILE_SKILLS = "ST_PROFILE_SKILLS";
export const ST_PROFILE_SALARY = "ST_PROFILE_SALARY";
export const FILE_UPLOADING = "FILE_UPLOADING";
