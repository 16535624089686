/**
 * @author: Ashay Tiwari
 * @date: 10 July 2021
 * @description: File for Company Signup Page 4 Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const COMPANY_LOOKING_FOR = "COMPANY_LOOKING_FOR";
export const COMPANY_LANGUAGES = "COMPANY_LANGUAGES";
export const COMPANY_LEVEL = "COMPANY_LEVEL";
export const COMPANY_JOB_OFFER = "COMPANY_JOB_OFFER";
export const COMPANY_LOOKING_STAFF = "COMPANY_LOOKING_STAFF";
export const COMPANY_STUDY = "COMPANY_STUDY";
export const COMPANY_CANDIDATE_SKILLS = "COMPANY_CANDIDATE_SKILLS";
export const COMPANY_UNIVERSITY = "COMPANY_UNIVERSITY";
export const COMPANY_OTHER_UNIVERSITY = "COMPANY_OTHER_UNIVERSITY";
export const COMPANY_ADVANTAGE = "COMPANY_ADVANTAGE";
export const COMPANY_CANDIDATE_TRAINING_EXP = "COMPANY_CANDIDATE_TRAINING_EXP";
export const COMPANY_CANDIDATE_SPECIALIZATION =
  "COMPANY_CANDIDATE_SPECIALIZATION";
export const COMPANY_FILE = "COMPANY_FILE";
