/**
 * @author: Ashay Tiwari
 * @date: 11 July 2021
 * @description: File for passing reducer function of student signup page 5
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  STUDENT_ABROAD_EXPERIENCE,
  STUDENT_CERTIFICATE,
  STUDENT_WORK_EXPERIENCE
} from "../../constants/action-types/StudentSignup/signup5.actionTypes";

const initialState = {
  studentWorkExperience: [
    {
      currentlyWorking: "134",
      jobType: "",
      companyName: "",
      workOnMonth: "",
      workOnYear: "",
      finishedWorkOnMonth: "",
      finishedWorkOnYear: ""
    }
  ],
  studentAbroadExperience: [
    {
      studiedAbroad: "134",
      aboradName: "",
      abroadUniversity: "",
      abroadOnMonth: "",
      abroadOnYear: "",
      abroadFinishedOnMonth: "",
      abroadFinishedOnYear: ""
    }
  ],
  studentCertficate: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STUDENT_WORK_EXPERIENCE:
      return {
        ...state,
        studentWorkExperience: payload
      };
    case STUDENT_ABROAD_EXPERIENCE:
      return {
        ...state,
        studentAbroadExperience: payload
      };
    case STUDENT_CERTIFICATE:
      return {
        ...state,
        studentCertficate: payload
      };
    default:
      return state;
  }
};
