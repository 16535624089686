/**
 * @author: Ashay Tiwari
 * @date: 12 Aug 2021
 * @description: File for passing reducer function of chat actions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  SHOW_USER_CHAT_HISTORY,
  SHOW_USER_COMMUNICATED_LIST
} from "../constants/action-types/chat.actionTypes";

const initialState = {
  chatHistory: [],
  chatList: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_USER_CHAT_HISTORY:
      return {
        ...state,
        chatHistory: payload
      };
    case SHOW_USER_COMMUNICATED_LIST:
      return {
        ...state,
        chatList: payload
      };
    default:
      return state;
  }
};
